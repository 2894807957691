<template>
  <VRow class="mx-0">
    <VCol cols="12">
      <p 
        class="mb-8"
        style="font-size: 18px;"
      >
        Bulk upload email addresses. Drag and drop a file to the box below to get started. Make sure “Email” is the first column. Accepted formats: .xls, .xlsx, .csv,  .sheets.
      </p>
      <VCard 
        color="#FAF9FF"
        height="110px"
        width="720px"
        class="d-flex align-center justify-center"
        style="font-size: 15px; border-radius: 10px;"
      >
        <img 
          src="@/images/spreadsheet-icon.png"
          class="mr-2"
        >
        Drag and drop your CSV here
      </VCard>
    </VCol>
  </VRow>
</template>
<script>
export default {
  name: "Import",
}
</script>