<template>
  <VRow
    :class="{
      'people-section': true,
      'people-dashboard-view': isDashboard,
    }"
  >
    <VCol cols="12">
      <DataViewUI
        hide-actions
        hide-records-info
        sort-desc
        sort-by="engagementLevel"
        search-prepend-icon=""
        search-label="Search..."
        search-append-icon="search"
        actions-header-text="Options"
        :data="infiniteLoad"
        :headers="headers"
        :hide-main-title="isDashboard"
        :is-loading="activity.isLoading"
        :enable-infinite-loader="!isDashboard"
        :hide-item-actions="$isUserStudent || $viewProgramAsStudent"
        @search="onSearch"
        @paginate="onGetMore"
        @infiniteLoad="onInfiniteLoad"
      >
        <template #title>
          <span 
            class="label-text text-truncate"
            style="font-size: 24px;"
          >
            All People
          </span>
        </template>
        <template 
          v-if="isDashboard && infiniteLoad.total > 8"
          #footer
        >
          <div 
            class="text-center py-4 mb-4"
            :colspan="headers.length"
          >
            <router-link 
              class="text-caption font-weight-medium text-decoration-underline d-inline-block"
              style="color: inherit;"
              :to="{
                name: 'program.view',
                hash: '#people',
                params: {
                  id: model.id,
                  team: $team.slug,
                }
              }"
            >
              Showing {{ infiniteLoad.data.length }} of {{ infiniteLoad.total }} People. Click here to view all users.
            </router-link>
            <VIcon 
              color="#000000de"
              size="18px"
              class="pl-1"
            >
              mdi-open-in-new
            </VIcon>
          </div>
        </template>
        <template slot="action-right">
          <div>
            <VMenu
              bottom
              right
              offset-y
              close-on-content-click
              nudge-bottom="4"
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  color="#0A5894"
                  height="44px"
                  class="no-shadow px-3 ml-4"
                >
                  <VIcon color="#0A5894">
                    mdi-filter-variant
                  </VIcon>
                  <span class="text-caption font-weight-bold pl-1">
                    {{ filterBy == "all" ? "Filter Results" : "Filtered for " + featureName(filterBy, "pluralize").capitalize() }}
                  </span>
                </VBtn>
              </template>
              <VList style="border-radius: 10px;">
                <VRadioGroup
                  hide-details
                  class="ma-0"
                  :value="filterBy"
                >
                  <VListItem
                    link
                    class="px-2"
                    @click="onSelectFilter('all')"
                  >
                    <VListItemTitle class="d-flex align-center">
                      <VRadio
                        color="success"
                        class="custom-radio mx-1 mb-1"
                        value="all"
                      />
                      <span
                        class="font-weight-medium"
                        style="font-size: 14px;"
                      >
                        All Users
                      </span>
                    </VListItemTitle>
                  </VListItem>
                  <VListItem
                    v-for="(role, index) in validRoles($team.roles, ['organization-admin'])"
                    link
                    class="px-2"
                    :key="index"
                    @click="onSelectFilter(role.value)"
                  >
                    <VListItemTitle class="d-flex align-center">
                      <VRadio
                        color="success"
                        class="custom-radio mx-1 mb-1"
                        :value="role.value"
                      />
                      <span
                        class="font-weight-medium"
                        style="font-size: 14px;"
                      >
                        {{ role.title.pluralize() }}
                      </span>
                    </VListItemTitle>
                  </VListItem>
                </VRadioGroup>
              </VList>
            </VMenu>
            <VBtn
              v-if="($isUserAdmin && $viewProgramAsAdmin) || checkMentorPermission('invite', model)"
              outlined
              height="44px"
              color="#0A5894"
              class="no-shadow px-4 ml-4"
              style="border-radius: 9px; background-color: #FFFFFF;"
              @click="$router.push({
                name: 'program.view',
                hash: '#people-invitations',
                params: {
                  id: model.id,
                  team: $team.slug,
                }
              })"
            >
              <VIcon color="#0A5894">
                add
              </VIcon>
              <span class="text-caption font-weight-bold pl-1">
                Add New
              </span> 
            </VBtn>
          </div>
        </template>
        <template
          slot="items-cells"
          slot-scope="props"
        >
          <td>
            <VList>
              <VListItem class="px-0">
                <VListItemAvatar 
                  size="36px"
                  style="border: 1px solid #0A5894;"
                >
                  <img :src="props.item.photo_url">
                </VListItemAvatar>
                <VListItemContent>
                  <VListItemTitle class="text-subtitle-2">
                    {{ props.item.name }}
                  </VListItemTitle>
                  <VListItemSubtitle 
                    v-if="!($isUserStudent || $viewProgramAsStudent)"
                    class="text-caption font-weight-medium"
                    style="color: #6E6E72;"
                  >
                    {{ props.item.email }}
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
            </VList>
          </td>
          <td>
            {{ featureName(props.item.attachment_meta.type, "capitalize") }}
          </td>
          <td v-if="!($isUserStudent || $viewProgramAsStudent)">
            <VChip
              v-if="props.item.engagement_level == 'not-approved'"
              color="disabled"
              label
            >
              Not Approved
            </VChip>
            <template v-else-if="props.item.engagement_level == 'engaged'">
              <VIcon 
                color="success"
                class="mr-2"
              >
                mdi-check-circle
              </VIcon>
              <span class="success--text">{{ props.item.last_engaged }} Days</span>
            </template>
            <template v-else-if="props.item.engagement_level == 'at-risk'">
              <VIcon 
                color="warning"
                class="mr-2"
              >
                mdi-help-circle
              </VIcon>
              <span class="warning--text">{{ props.item.last_engaged }} Days</span>
            </template>
            <template v-else-if="props.item.last_engaged > 0">
              <VIcon 
                color="error"
                class="mr-2"
              >
                mdi-alert-circle
              </VIcon>
              <span class="error--text">{{ props.item.last_engaged }} Days</span>
            </template>
            <VChip
              v-else
              label
              color="error"
              text-color="white"
            >
              Not Active
            </VChip>
          </td>
          <td>{{ props.item.city ? props.item.city.name.trim() + ", " + props.item.city.parent.name : "" }}</td>
          <td 
            v-if="!($isUserStudent || $viewProgramAsStudent)"
            class="actions"
          >
            <VProgressCircular 
              v-if="props.item.isRemoving" 
              indeterminate
              color="primary"
            />
            <Menu 
              v-else
              :disabled="getIsDisabled(props.item)"
            >
              <template #icon>
                <VIcon
                  color="#252526"
                  style="font-size: 28px"
                >
                  mdi-dots-horizontal
                </VIcon>
              </template>
              <VListItem 
                v-if="($isUserAdmin && $viewProgramAsAdmin) || checkMentorPermission('invite', model)"
                @click="onSetFormDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    edit
                  </VIcon>&nbsp;Edit
                </VListItemTitle>
              </VListItem>
              <VListItem @click="onSetViewDialogModel(props.item)">
                <VListItemTitle>
                  <VIcon small>
                    visibility
                  </VIcon>&nbsp;View
                </VListItemTitle>
              </VListItem>
              <VListItem 
                v-if="($isUserAdmin && $viewProgramAsAdmin) || checkMentorPermission('invite', model)"
                @click="removeUser(props.item, props.index)"
              >
                <VListItemTitle color="error">
                  <VIcon color="error">
                    mdi-close
                  </VIcon>&nbsp;Remove from {{ featureName("Program", "singularize") }}
                </VListItemTitle>
              </VListItem>
            </Menu>
          </td>
        </template>
      </DataViewUI>
      <Dialog 
        max-width="525px"
        card-text-class="px-0"
        default-content-class=""
        :dismissable="false"
        :is-dialog-open="dialogs.view"
      >
        <UserProfile
          :user="currentModel"
          :id="currentModel ? currentModel.id : null"
          @close="onToggleViewDialog"
        />
      </Dialog>
      <FormDialog
        max-width="500px"
        :can-delete="false"
        :is-dialog-open="dialogs.form"
        :title="`Update Role: ${currentModel ? currentModel.email : ''}`"
        @toggle:form:dialog="onToggleFormDialog"
      >
        <UpdateUserRole 
          :program-id="model.id"
          :use-id-from-route="false"
          :user-form="currentModel"
          @toggle:form:dialog="onToggleFormDialog"
        />
      </FormDialog>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import ViewProgramSection from "@/mixins/ViewProgramSection";
import UserProfile from "@/components/Elements/UserProfile";
import UpdateUserRole from "./UpdateRole";
export default {
  name: "People",
  mixins: [ViewProgramSection],
  components: {
    UserProfile,
    UpdateUserRole,
  },
  data() {
    return {
      filterBy: "all",
      getAllOnLoad: true,
      enableInfiniteLoader: true,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$isUserStudent || this.$viewProgramAsStudent ? "Username" : "Username & Email",
          align: "left",
          value: "name",
          sortable: true,
          enabled: true,
        },
        {
          text: "Role",
          align: "left",
          value: "programRole",
          sortable: true,
          enabled: true,
        },
        {
          text: "Last Engaged",
          align: "left",
          value: "engagementLevel",
          sortable: true,
          enabled: !(this.$isUserStudent || this.$viewProgramAsStudent),
        },
        {
          text: "Location",
          align: "left",
          value: "city.name",
          sortable: false,
          enabled: true,
        },
      ].filter((item) => item.enabled === true);
    },
  },
  methods: {
    ...mapActions({
      doGetProgramUsers: "program/users",
      doRemoveProgramUser: "program/removeUser",
    }),
    onSelectFilter(value) {
      this.filterBy = value;
      this.onFilterData();
    },
    onGetAll(params) {
      params.perPage = this.isDashboard ? 8 : 20;
      params.sort = params.sort || ["engagementLevel"];
      params.order = params.order || [true];
      params.program_id = this.model.id;
      if(this.filterBy != "all") {
        params.roles = [this.filterBy];
      }
      return this.doGetProgramUsers(params);
    },
    removeUser(user, index) {
      const params = {
        program_id: this.model.id,
        user_id: user.id,
      };
      this.$set(this.infiniteLoad.data[index], "isRemoving", true);
      this.doRemoveProgramUser(params).then((result) => {
        if(result) {
          this.infiniteLoad.data.splice(index, 1);
          this.$bus.$emit("notificationMessage", "User removed successfully!");
        }
      });
    },
  },
};
</script>
<style lang="scss">
.people-dashboard-view {
  .v-data-table {
    margin-top: 12px !important;
  }
}
</style>