<template>
  <VRow 
    id="invite-form"
    class="mx-0"
  >
    <VCol cols="12">
      <p 
        class="mb-0"
        style="font-size: 18px;"
      >
        Does what it says on the tin. Go ahead, get started.
      </p>
    </VCol>
    <VCol 
      cols="12"
      class="pb-0"
    >
      <DataViewUI
        hide-actions
        hide-records-info
        hide-item-actions
        hide-main-title
        :data="form"
        :headers="headers"
      >
        <template #items-cells="props">
          <td>
            <TextField
              v-model="props.item.email"
              hide-details
              height="52px"
              type="email"
              placeholder="Email Address"
              :error="$v.form.data.$each[props.index].email.$error"
              @input="onInputEmail(props.index)"
              @blur="$v.$touch()"
            />
          </td>
          <td class="text-center">
            <VTabs
              centered
              hide-slider
              height="52px"
              active-class="rounded-pill"
              class="rounded-pill my-4 mx-auto"
              style="border: 2px solid #0A5894; width: min-content;"
              @change="(value) => props.item.role = value ? 'mentor' : 'student'"
            >
              <VTab>{{ featureName("Student") }}</VTab>
              <VTab>{{ featureName("Mentor") }}</VTab>
            </VTabs>
          </td>
          <td class="text-center">
            <VBtn
              v-if="form.data.length > 1"
              icon
              outlined
              width="44px"
              height="44px"
              color="#FF2A7A"
              style="border: 2px solid rgb(255, 42, 122);"
              :disabled="props.item.isLoading || activity.isFormLoading"
              :loading="props.item.isLoading"
              @click="form.data.splice(props.index, 1)"
            >
              <VIcon 
                small
                style="font-weight: 700;"
              >
                close
              </VIcon>
            </VBtn>
          </td>
        </template>
      </DataViewUI>
    </VCol>
    <VCol 
      v-if="form.data.length == maxLimit"
      cols="12"
      class="d-flex align-center ml-4"
    >
      <VIcon 
        color="error"
        class="mr-2"
      >
        mdi-information
      </VIcon>
      <span style="font-size: 15px;">
        If you wish to invite more than 10, use the bulk upload
      </span>
    </VCol>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
export default {
  name: "InviteForm",
  mixins: [FormMixin],
  props: {
    programId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      modelType: "invitation",
      maxLimit: 10,
      defaultForm: {
        data: [
          {
            email: "",
            role: "student",
          },
        ],
      },
    };
  },
  validations: {
    form: {
      data: {
        $each: {
          email: {
            required,
            email,
          },
          role: {
            required,
          },
        },
      },
    },
  },
  watch: {
    $v: {
      handler: function(value) {
        this.$emit("disabled", value.$invalid || value.$error);
      },
      deep: true,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Email Address",
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: "Role",
          align: "center",
          sortable: false,
          value: "",
        },
        {
          text: "Remove",
          align: "center",
          sortable: false,
          value: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      doSendInviteToProgrm: "team/inviteToProgram",
    }),
    onInputEmail(index) {
      this.$v.form.data.$each[index].email.$touch();
      if(this.form.data.length === index + 1 && this.form.data.length < this.maxLimit) {
        this.form.data.push({
          email: "",
          role: "student",
        });
        this.$nextTick(() => {
          const container = document.querySelector(".form-dialog .v-card__text");
          container.scrollTo(0, container.scrollHeight);
        });
      }
    },
    onSave() {
      const params = {
        users: this.form.data,
        program_id: this.programId,
      };
      this.$emit("loading", true);
      return this.saveFormModel(this.doSendInviteToProgrm, null, params, null, false).then((result) => {
        if(result) {
          const nonExistingUsers = result.filter((invitation) => invitation.hasOwnProperty("email"));
          const existingUsers = params.users.filter((user) => {
            const index = result.findIndex((invitation) => invitation.hasOwnProperty("email") && invitation.email == user.email);
            return index === -1;
          });
          if(nonExistingUsers.length) {
            this.$bus.$emit("notificationMessage", "Invitations sent successfully");
          }
          this.$emit("sent", { 
            existingUsers,
            nonExistingUsers,
          });
        }
        return result;
      });
    },
  },
}
</script>
<style lang="scss">
#invite-form {
  .v-data-table__wrapper > table > thead > tr > th {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #42526E;
  }
  .v-data-table tbody tr > td {
    background-color: #FFFFFF;
  }
  .v-tabs-bar {
    padding: 8px !important;
  }
  .v-tabs-bar__content {
    padding: 0px !important;
    margin-top: 0px !important;
    background-color: transparent !important;
    .v-tab--active {
      border: 2px solid #0A5894;
      color: #0A5894;
    }
  }
  .v-tab {
    font-size: 12px;
    font-weight: 500;
    height: 36px !important;
    min-width: 84px !important;
  }
  .v-input:not(.v-input--checkbox):not(.v-input--radio):not(.v-input--switch) fieldset {
    border-radius: 14px;
    border: 1px solid #ECECF1;
    background-color: #FAF9FF;
  }
  .error--text.v-input:not(.v-input--checkbox):not(.v-input--radio) .v-input__slot {
    border-radius: 14px;
  }
  .v-input input, .v-input input::placeholder {
    font-size: 14px;
  }
}
</style>