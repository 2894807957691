<template>
  <VRow id="people-invitations">
    <VCol class="12">
      <h3 
        class="label-text"
        style="font-size: 24px;"
      >
        Invite & Add Users to This {{ featureName("Program", "singularize") }}
      </h3>
      <p
        class="py-2" 
        style="font-size: 18px;"
      >
        You can add existing workspace users to this {{ featureName("Program", "singularize") }}, or manually invite users by their email addresses, including bulk upload.
      </p>
      <VCard
        class="my-4" 
        style="padding: 10px;"
      >
        <VCardText>
          <h4 class="text-h6 font-weight-black">
            Add Users from Other {{ featureName("Programs") }}
          </h4>
          <VCard
            color="#FAF9FF"
            class="d-flex mt-2"
            style="padding: 10px;"
          >
            <VCardText class="d-flex align-center">
              <p style="font-size: 18px;">
                Tap into your existing Workspace network to easily add users who already moderate or participate as {{ featureName("students") }} in other {{ featureName("programs") }} within your workspace.
              </p>
            </VCardText>
            <VCardActions>
              <VBtn
                outlined
                height="44px"
                color="#0A5894"
                class="no-shadow px-3"
                style="border-radius: 9px; background-color: #FFFFFF;"
                @click="dialogs.addUsers.status = true"
              >
                <span class="text-caption font-weight-bold pl-1">
                  Select Users
                </span> 
              </VBtn>
            </VCardActions>
          </VCard>
        </VCardText>
      </VCard>
      <VCard style="padding: 10px;">
        <VCardText>
          <h4 class="text-h6 font-weight-black">
            Invite Users via Email
          </h4>
          <p 
            class="py-2"
            style="font-size: 18px;"
          >
            You can add new {{ featureName("students") }} and {{ featureName("mentors") }} to this {{ featureName("program", "singularize") }} via email. {{ featureName("Students") }} can view all {{ featureName("program", "singularize") }} content. {{ featureName("Mentors") }} can be granted additional permissions to create {{ featureName("program", "singularize") }} content and manage {{ featureName("program", "singularize") }} {{ featureName("students") }}. To invite new Workspace Admins, go to your Workspace Settings.
          </p>
          <h4 class="text-h6 mt-8 font-weight-black">
            Send Email Invitations
          </h4>
          <VCard
            color="#FAF9FF"
            class="d-flex mt-2"
            style="padding: 10px;"
          >
            <VCardText class="d-flex align-center">
              <p style="font-size: 18px;">
                Invite new {{ featureName("students") }} and {{ featureName("mentors") }} to this {{ featureName("program", "singularize") }} by email.
              </p>
            </VCardText>
            <VCardActions>
              <VBtn
                outlined
                height="44px"
                color="#0A5894"
                class="no-shadow px-3"
                style="border-radius: 9px; background-color: #FFFFFF;"
                @click="dialogs.inviteForm.status = true"
              >
                <span class="text-caption font-weight-bold pl-1">
                  Add Emails
                </span> 
              </VBtn>
            </VCardActions>
          </VCard>
          <h4 class="text-h6 mt-10 font-weight-black">
            Bulk Import Emails
          </h4>
          <VCard
            color="#FAF9FF"
            class="d-flex mt-2"
            style="padding: 10px;"
          >
            <VCardText class="d-flex align-center">
              <p style="font-size: 18px;">
                For when you’re inviting more than one, nothing does it quite like a bulk upload. 
              </p>
            </VCardText>
            <VCardActions>
              <VBtn
                outlined
                height="44px"
                color="#0A5894"
                class="no-shadow px-3"
                style="border-radius: 9px; background-color: #FFFFFF;"
                @click="dialogs.bulkUpload = true"
              >
                <span class="text-caption font-weight-bold pl-1">
                  Upload File
                </span> 
              </VBtn>
            </VCardActions>
          </VCard>
          <h4 class="text-h6 mt-10 font-weight-black">
            Pending Email Invitations
          </h4>
          <VSkeletonLoader
            grid-list-lg
            fluid
            type="card"
            class="mt-2"
            :loading="activity.isLoading && !isDataAvailable"
          >
            <VCard
              v-if="!isDataAvailable"
              color="#FAF9FF"
              style="padding: 10px;"
            >
              <VCardText>
                <p style="font-size: 18px;">
                  This area will show anyone who’s been invited via email and has not yet accepted. At the moment, your {{ featureName("Program", "singularize") }} has no pending invitations. Use the buttons above to invite new users, or click here to view 
                  <router-link
                    :to="{
                      name: 'program.view',
                      hash: '#people',
                      params: {
                        id: model.id,
                        team: $team.slug,
                      }
                    }"
                  >
                    All People
                  </router-link>. 
                </p>
              </VCardText>
            </VCard>
            <VRow 
              v-else
              class="people-section"
            >
              <VCol>
                <DataViewUI
                  hide-actions
                  hide-item-actions
                  hide-records-info
                  enable-infinite-loader
                  search-prepend-icon=""
                  search-label="Search..."
                  search-append-icon="search"
                  actions-header-text="Cancel"
                  :data="infiniteLoad"
                  :headers="headers"
                  :is-loading="activity.isLoading"
                  @search="onSearch"
                  @infiniteLoad="onInfiniteLoad"
                >
                  <template
                    slot="items-cells"
                    slot-scope="props"
                  >
                    <td>{{ props.item.email }}</td>
                    <td>{{ featureName(props.item.role, 'titleize') }}</td>
                    <td>{{ $timezone.formatDateTime(props.item.created_at) }}</td>
                    <td>
                      <VBtn
                        icon
                        outlined
                        width="44px"
                        height="44px"
                        color="#FF2A7A"
                        style="border: 2px solid rgb(255, 42, 122);"
                        :disabled="props.item.isLoading"
                        :loading="props.item.isLoading"
                        @click="cancel(props.item, props.index)"
                      >
                        <VIcon 
                          small
                          style="font-weight: 700;"
                        >
                          close
                        </VIcon>
                      </VBtn>
                    </td>
                  </template>
                </DataViewUI>
              </VCol>
            </VRow>
          </VSkeletonLoader>
        </VCardText>
      </VCard>
      <Confirm ref="confirmDelete" />
      <FormDialog
        hide-title-border
        max-width="760px"
        close-icon-color="#111111"
        custom-class="invite-form-dialog"
        :is-dialog-open="dialogs.inviteForm.status"
        @toggle:form:dialog="dialogs.inviteForm.status = false"
      >
        <template #title>
          <h2 
            class="text-h6 font-weight-black pt-4"
            style="padding-left: 10px;"
          >
            Invite Users by Email Address
          </h2>
        </template>
        <template #card-actions>
          <div class="fluid text-center">
            <VBtn
              color="#0A5894"
              height="44px"
              class="send-invite-button my-3"
              style="border-radius: 9px;"
              :disabled="dialogs.inviteForm.disabled"
              :loading="dialogs.inviteForm.loading"
              @click="$refs.inviteForm.onSave()"
            >
              <img src="@/images/send-icon-white.png">
              <span 
                class="text-caption white--text pl-1"
                style="font-weight: 700;"
              >
                Send Invites
              </span>
            </VBtn>
          </div>
        </template>
        <InviteForm 
          ref="inviteForm"
          :program-id="model.id"
          @disabled="(status) => dialogs.inviteForm.disabled = status"
          @loading="(status) => dialogs.inviteForm.loading = status"
          @sent="onInvitationsSent"
        />
      </FormDialog>
      <Dialog
        enable-footer-slots
        max-width="760px"
        custom-class="add-users-dialog"
        :enable-cancel-button="false"
        :enable-save-button="false"
        :is-dialog-open="dialogs.addUsers.status"
        @toggle:dialog="dialogs.addUsers.status = false"
      >
        <template #title>
          <h2 
            class="text-h6 font-weight-black pt-4"
            style="padding-left: 10px;"
          >
            Add Users from Other {{ featureName("Programs") }}
          </h2>
        </template>
        <template #footer-center>
          <div class="fluid text-center">
            <VBtn
              color="#0A5894"
              height="44px"
              class="send-invite-button"
              style="border-radius: 9px;"
              :disabled="dialogs.addUsers.disabled"
              :loading="dialogs.addUsers.loading"
              @click="$refs.addUsers.onSave()"
            >
              <VIcon color="white">
                add
              </VIcon>
              <span 
                class="text-caption white--text pl-1"
                style="font-weight: 700;"
              >
                Add Selected Users to {{ featureName("Program", "singularize") }}
              </span>
            </VBtn>
          </div>
        </template>
        <AddUsers 
          ref="addUsers"
          :program-id="model.id"
          @disabled="(status) => dialogs.addUsers.disabled = status"
          @loading="(status) => dialogs.addUsers.loading = status"
        />
      </Dialog>
      <Dialog
        enable-footer-slots
        max-width="760px"
        custom-class="add-users-dialog"
        :enable-cancel-button="false"
        :enable-save-button="false"
        :is-dialog-open="dialogs.bulkUpload"
        @toggle:dialog="dialogs.bulkUpload = false"
      >
        <template #title>
          <h2 
            class="text-h6 font-weight-black pt-4"
            style="padding-left: 10px;"
          >
            Upload File
          </h2>
        </template>
        <Import />
      </Dialog>
      <Dialog
        max-width="755px"
        :is-dialog-open="dialogs.sentInvitations.status"
        :title="`View Sent Invitations for ${model.title}`"
        @toggle:dialog="dialogs.sentInvitations.status = false"
      >
        <VCard>
          <VCardText>
            <VAlert
              color="rgba(73, 148, 236, 0.2)"
              class="subtitle-2 font-weight-medium"
              style="border-radius: 14px"
            >
              <template #prepend>
                <VIcon
                  color="#4994EC"
                  class="pr-3"
                >
                  mdi-information
                </VIcon>
              </template>
              These users already have WeThrive accounts and have automatically been added to the {{ featureName("program", "singularize") }}!
            </VAlert>
            <VChip
              v-for="(user, index) in dialogs.sentInvitations.data"
              class="ma-2"
              :key="index"
            >
              {{ user.email }}
            </VChip>
          </VCardText>
        </VCard>
      </Dialog>
    </VCol>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";
import ViewProgramSection from "@/mixins/ViewProgramSection";
import InviteForm from "./InviteForm";
import AddUsers from "./Add";
import Import from "./Import";
export default {
  name: "Invitations",
  mixins: [ViewProgramSection],
  components: {
    InviteForm,
    AddUsers,
    Import,
  },
  data() {
    return {
      getAllOnLoad: true,
      enableInfiniteLoader: true,
      isDataAvailable: false,
      dialogs: {
        inviteForm: {
          disabled: true,
          loading: false,
          status: false,
        },
        addUsers: {
          disabled: true,
          loading: false,
          status: false,
        },
        sentInvitations: {
          data: [],
          status: false,
        },
        bulkUpload: false,
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Email",
          align: "left",
          value: "email",
          sortable: false,
          enabled: true,
        },
        {
          text: "Role",
          align: "left",
          value: "role",
          sortable: false,
          enabled: true,
        },
        {
          text: "Sent On",
          align: "left",
          value: "created_at",
          sortable: false,
          enabled: true,
        },
        {
          text: "Cancel",
          align: "left",
          value: "",
          sortable: false,
          enabled: true,
        },
      ].filter((item) => item.enabled === true);
    },
  },
  methods: {
    ...mapActions({
      doGetInvitations: "team/invitations",
      doDeleteInvite: "team/deleteInvite",
    }),
    onGetAll(params) {
      params.sort = ["created_at"];
      params.order = [true];
      params.filter = {
        program: this.model.id,
      };
      return this.doGetInvitations(params).then((result) => {
        if(!this.isDataAvailable && result.total > 0) {
          this.isDataAvailable = true;
        }
        return result;
      });
    },
    cancel(invitation, index) {
      this.$set(this.infiniteLoad.data[index], "isLoading", true);
      this.onDeleteItem([invitation], this.doDeleteInvite, null, false).then((result) => {
        if(result) {
          this.infiniteLoad.data.splice(index, 1);
          this.$bus.$emit("notificationMessage", "Successfully invitation cancelled");
        }
      });
    },
    onInvitationsSent(invitations) {
      this.dialogs.inviteForm.loading = false;
      this.dialogs.inviteForm.status = false;
      if(invitations.nonExistingUsers.length) {
        this.infiniteLoad.data = [
          ...invitations.nonExistingUsers,
          ...this.infiniteLoad.data,
        ];
      }
      if(invitations.existingUsers.length) {
        this.$set(this.dialogs.sentInvitations, "data", invitations.existingUsers);
        this.$set(this.dialogs.sentInvitations, "status", true);
      }
    },
  },
}
</script>
<style lang="scss">
.send-invite-button.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #0A589466 !important;
}
.invite-form-dialog .v-card__title {
  .v-btn .v-icon {
    font-size: 28px;
    font-weight: 700;
  }
}
.add-users-dialog {
  .v-card__title {
    border-bottom: none !important;
    min-height: 52px !important;
    height: 52px !important;
    flex: none !important;

    .v-btn .v-icon {
      font-size: 28px;
      font-weight: 700;
      color: #111111 !important;
    }
  }
}
</style>